@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.assign-new-member {
  background-color: light.$background-secondary-default;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  &__back-button-wrapper {
    display: flex;
  }

  &__back-button, &__search-bar {
    padding: spacing.$m 0;
  }

  &__no-result-wrapper {
    border-top: 1px solid light.$ge-border-default;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: inherit;
  }

  &__placeholder-content, &__no-results {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  &__placeholder-icon, &__no-results-icon {
    padding: spacing.$l;
    border-radius: 100%;
    margin-bottom: spacing.$s;
    width: 90px;
    height: 90px;
  }

  &__no-results-icon {
    background-color: light.$surface-secondary-default;
    color: light.$ge-icons-secondary;
  }

  &__placeholder-icon {
    background-color: light.$signal-surface-information;
    color: light.$on-signal-surface-information-alternate;
  }

  &__placeholder-content-text {
    text-align: center;
  }

  &__member-wrapper {
    border-top: 1px solid light.$ge-border-default;
  }

  &__total-results {
    display: flex;
    padding-bottom: spacing.$m;

    > * {
      margin: 0 spacing.$xxs;
    }
  }

  &__more-results {
    background: light.$surface-secondary-default;
    border-radius: corner-radius.$s;
    padding: spacing.$m;
    margin: spacing.$m 0;
    > * + * {
      margin-top: spacing.$xs;
    }
  }
}
